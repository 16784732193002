
import { getProducts } from '@/api/billing.service'

export default {
  name: 'OpenPricingBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      products: [

      ],
    }
  },
  computed: {
    priceRenderer() {
      if (!this.blok) return
      return () => import(`@/components/${this.blok.renderer}`)
    },
  },
  async mounted() {
    try {
      const products = await this.$api.run(getProducts).request
      if (!products) return
      products.sort((prod1, prod2) => prod1.words_limit - prod2.words_limit)
      this.products = products
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
  },
}
